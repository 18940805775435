// @flow
import React from 'react';
import moment from 'moment';
import styles from './Meta.module.scss';

type Props = {
  date: string
};

const Meta = ({ date }: Props) => (
  <div className={styles['meta']}>
    <p className={styles['meta__date']}>
      Published {moment(date).format('D MMM YYYY')}
      {' on'} <a href="https://yazeedb.com">yazeedb.com</a>
    </p>
  </div>
);

export default Meta;
